import "./styles/typography.scss";
import Header from "./components/Header/Header";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header/>
      <Projects/>
      <Footer/>
    </div>
  );
}

export default App;
