import "./Case.scss";
import Tag from "../Tag/Tag";

function Case ({
    name,
    text,
    url,
    tag,
    colorText,
    colorName,
    caseUrl,
    poster
}) {
    return (
        <div className="case-project">
            <a href={caseUrl} rel="norefferer">
                <div className="description-case">
                    <Tag label={tag}></Tag>
                    <div className="info-case">
                        <h5 className={colorText}><span className={colorName}>{name}.</span>&nbsp;{text}</h5>
                    </div>
                </div>
                <video autoPlay muted video loop playsInline webkit-playinginline poster={poster}>
                    <source src={url} type="video/webm"/>
                </video>
            </a>
        </div>
    )
}

export default Case;