import "./Projects.scss";
import Case from "../Case/Case";

function Projects() {
    return ( 
        <div className="grid-projects">
            <Case caseUrl="https://securenote.pro/" tag="Веб-интерфейс" name="Secure Note" colorName="white-50" text="Сервис для передачи зашифрованных записок" colorText="white" url="" poster="img/secure-note.webp"/>
            <Case caseUrl="https://trebuchet.gibthf.com/" tag="Веб-интерфейс" name="Trebuchet" colorName="white-50" text="Инструмент оценки безопасности для тестирования защиты электронной почты и&nbsp;сети от целенаправленных атак." colorText="white" url="" poster="img/trebuchet.webp"/>
            <Case caseUrl="/projects/fraudprotection" tag="Веб-интерфейс" name="Fraud&nbsp;Protection" colorName="white-50" text="Предотвращение онлайн-мошенничества в&nbsp;цифровых каналах и&nbsp;в&nbsp;реальном времени" colorText="white" url="video/fhp.webm" poster="video/poster/poster-fhp.avif"/>
            <Case tag="Скоро" name="Проект X" colorName="black-50" text="В разработке и под NDA." colorText="black" url="video/app.webm" poster="video/poster/poster-app.avif"/>
            <Case caseUrl="https://groupib-concept.framer.website/" tag="Сайт" name="Group-IB" colorName="white-50" text="Концепция главной страницы сайта компании" colorText="white" url="video/gib.webm" poster="video/poster/poster-gib.avif"/>
            <Case caseUrl="/projects/victoriaivankovich" tag="Интернет-магазин" name="Victoria Ivankovich" colorName="white-50" text="Бренд женского нижнего белья" colorText="white" url="video/vi.webm" poster="video/poster/poster-vi.avif"/>
            <Case caseUrl="/projects/competenzlab" tag="Веб-интерфейс" name="CompetenzLab" colorName="white-50" text="Платформа для комплескного развития навыков и&nbsp;поиск работы" colorText="white" poster="video/poster/cl-interface.avif"/>
            <Case caseUrl="/projects/competenzlabweb" tag="Сайт" name="CompetenzLab" colorName="white-50" text="Лендинг для стартапа по развитию навыков и поиска работы" colorText="white" poster="video/poster/cl-land.avif"/>
            <Case caseUrl="https://cybercrimecon19.framer.website/" tag="Сайт" name="CyberCrimeCon/19" colorName="white-50" text="Промо-сайт для международной конференции по&nbsp;ИБ" colorText="white" url="https://firebasestorage.googleapis.com/v0/b/videoproject-9e0b9.appspot.com/o/cyber3dlogo-compress.mp4?alt=media&token=2ddadad0-cdf5-4b48-a645-d25a2641979b" poster="video/poster/poster-ccc19.avif"/>
            <Case caseUrl="/" tag="Интернет-магазин" name="GIB Store" colorName="white-50" text="Корпоративный интернет-магазин по продаже мерча Group-IB." colorText="white" poster="img/gibstore.avif"/>

        </div>
     );
}

export default Projects;