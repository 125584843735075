import "./Header.scss";
import Contacts from "../Contacts/Contacts";

function Header () {
    return (
        <div className="header-page">
            <h1>
                <span className="gray-color">
                    Владимир Белов.
                </span>
                &nbsp;Ведущий продуктовый дизайнер. Проектирую решения с комфортным использованием интерфейса и достижения пользовательских целей.
            </h1>
            <Contacts/>
        </div>
    );
}

export default Header;